/* ====== TO TOP ====== */
jQuery(document).ready(function ($) {
    var offset = 100,
            offset_opacity = 1200,
            scroll_top_duration = 700,
            $back_to_top = $('.cd-top');
    $(window).scroll(function () {
        ($(this).scrollTop() > offset) ? $back_to_top.addClass('cd-is-visible') : $back_to_top.removeClass('cd-is-visible cd-fade-out');
        if ($(this).scrollTop() > offset_opacity) {
            $back_to_top.addClass('cd-fade-out');
        }
    });
    $back_to_top.on('click', function (event) {
        event.preventDefault();
        $('body,html').animate({
            scrollTop: 0
        }, scroll_top_duration
                );
    });
});

const $dropdown = $(".dropdown");
const $dropdownToggle = $(".dropdown-toggle");
const $dropdownMenu = $(".dropdown-menu");
const showClass = "show";
 
$(window).on("load resize", function() {
  if (this.matchMedia("(min-width: 768px)").matches) {
    $dropdown.hover(
      function() {
        const $this = $(this);
        $this.addClass(showClass);
        $this.find($dropdownToggle).attr("aria-expanded", "true");
        $this.find($dropdownMenu).addClass(showClass);
      },
      function() {
        const $this = $(this);
        $this.removeClass(showClass);
        $this.find($dropdownToggle).attr("aria-expanded", "false");
        $this.find($dropdownMenu).removeClass(showClass);
      }
    );
  } else {
    $dropdown.off("mouseenter mouseleave");
  }
});

/* ====== FLEX SLIDER ====== */
$(".flexslider").flexslider({
    slideshow: true,
    animation: "fade",
    slideshowSpeed: 5000,
    animationLoop: true,
    pauseOnHover: false,
    controlNav: false,
    keyboard: false
});

/* ====== HP BLOG ====== */
$(".hp_prod").slick({
    variableWidth: true,
    infinite: true,
    autoplay: false,
    arrows: true,
    prevArrow: $('.prev'),
    nextArrow: $('.next'),
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplaySpeed: 7000
});

/* ====== HP BLOG ====== */
$(".single_prod").slick({
    infinite: false,
    autoplay: true,
    arrows: true,
    prevArrow: $('.prev'),
    nextArrow: $('.next'),
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplaySpeed: 7000
});